import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './images/borger_dk_normal.svg'; // Replace with your actual path to the logo image
import borgerLogo from './images/borger_dk_inverted.svg';
import heroImage from './images/MTkzMzA2Mjg1MjMwNzk0MzA3.webp'; // Replace with your actual path to the hero image

import './App.css';

const generateReferenceID = () => {
  return `REF-${Math.floor(100000 + Math.random() * 900000)}`;
};

const SuccessPage = () => {
  const referenceID = generateReferenceID();

  const handleLogout = () => {
    window.location.href = 'https://www.borger.dk';
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-light" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={logo} alt="Logo" width="auto" height="30" className="d-inline-block align-top" />
          </a>
        </div>
      </nav>

      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
           backgroundImage: `url(${heroImage})`,
          backgroundColor: '#f2f2f2',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '30vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          position: 'relative',
        }}
      >
        <div className="hero-overlay"></div>
        <div className="container text-center">
          <div className="hero-content">
            <div
              className="card p-3"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <h1 className="hero-title mb-0" style={{ color: '#44831e' }}>
                Succes
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Success Message Section */}
      <div className="container-fluid d-flex justify-content-center align-items-center py-5">
        <div
          className="card border-0"
          style={{ width: '100%', maxWidth: '600px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="card-body text-center">
            <h1 className="card-title mb-4" style={{ color: '#44831e' }}>
              Oplysninger opdateret
            </h1>
            <p className="card-text mb-4" style={{ color: '#44831e' }}>
              Dine oplysninger er blevet opdateret med succes. Du vil snart blive kontaktet af en af vores repræsentanter.
            </p>
            <p className="card-text mb-4" style={{ color: '#44831e', fontWeight: 'bold', fontSize: '1.2rem' }}>
              Reference ID: <span style={{ color: '#17750d', backgroundColor: '#f2f2f2', padding: '5px 10px', borderRadius: '5px' }}>{referenceID}</span>
            </p>
            <button
              className="btn btn-primary btn-lg btn-block rounded-pill"
              style={{
                backgroundColor: '#44831e',
                borderColor: '#44831e',
                color: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              onClick={handleLogout}
            >
              Log ud
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#44831e', color: 'white', paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 mb-3 mb-md-0 text-center">
            <img src={borgerLogo} alt="Borger.dk Logo" style={{ maxWidth: '30%', height: 'auto' }} />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Kontakt</h6>
                <ul className="list-unstyled">
                  <li><a href="#" style={{ color: 'white' }}>Hjælp og vejledning</a></li>
                  <li><a href="#" style={{ color: 'white' }}>Find din kommune eller anden myndighed</a></li>
                </ul>
              </div>
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Om borger.dk</h6>
                <ul className="list-unstyled">
                  <li><a href="#" style={{ color: 'white' }}>Tilgængelighedserklæring</a></li>
                  <li><a href="#" style={{ color: 'white' }}>Cookies og privatlivspolitik</a></li>
                  <li><a href="#" style={{ color: 'white' }}>English</a></li>
                </ul>
              </div>
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Webtilgængelighed</h6>
                <ul className="list-unstyled">
                  <li><a href="#" style={{ color: 'white' }}>Adgang med tegn</a></li>
                  <li><a href="#" style={{ color: 'white' }}>Få teksten læst op</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SuccessPage;
